import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper,
    DataTransformer,
    InvoiceClickStrategy,
    InvoiceDisplayStrategy,
    InvoiceSelectedActions,
    TableFieldDescription,
    ThousandSeparatorCreator,
    DateComponentCreator,
    DateDisplayCreator
} from '../../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from '../../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllInvoices(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $state:String,
        $identifier:String,
        $accountIdentifier:String,
        $legalEntity:String,
        $provider:String,
        $currency:String,
        $date:String,
        $dueDate:String,
        $serviceFrom:String,
        $description:String,
        $serviceTo:String,
        $country:String,
        $domain:String,
    ) {
        allInvoices(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            state:$state,
            identifier:$identifier,
            accountIdentifier:$accountIdentifier,
            legalEntity:$legalEntity,
            provider:$provider,
            currency:$currency,
            date:$date,
            dueDate:$dueDate,
            serviceFrom:$serviceFrom,
            serviceTo:$serviceTo,
            description:$description,
            country:$country,
            domain:$domain,
        ) {
            edges {
                node {
                    id
                    state
                    canBeValidated
                    billingRequest {
                        id
                        driver {
                            id
                            currency {
                                id
                                code
                            }
                        }
                    }
                    domain {
                        id
                        name
                    }
                    account {
                        id
                        identifier
                        description
                        validated
                        legalEntity {
                            id
                            name
                        }
                        provider {
                            id
                            name
                            country{
                                name
                                id
                            }
                        }
                    }
                    identifier
                    date
                    dueDate
                    serviceFrom
                    serviceTo
                    totalCost
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

let editMutator

let newMutator

let deleteMutator

const unitQuery = `
    invoice(id: $id) {
        id
        state
        canBeValidated
        domain {
            id
            name
        }
        billingRequest {
            id
            billingrequestfileSet {
                id
                driverFile {
                    extension
                    name
                }
            }
            driver{
                id
                currency{
                    id
                    code
                }
            }
        }
        account {
            id
            identifier
            description
            validated
            legalEntity {
                id
                name
            }
            provider {
                id
                name
                country{
                    name
                    id
                }
            }
        }
        identifier
        date
        dueDate
        serviceFrom
        serviceTo
        totalCost
    }
    unassignedChargeDescriptions(id: $id) {
        id
        name
    }
    unassignedDestinationDescriptions(id: $id) {
        id
        name
    }
    unassignedConcepts(id: $id) {
        id
        name
    }
    availableConcepts {
        id
        name
        technology {
            id
            name
        }
    }
    availableChargeGroups {
        id
        name
    }
    availableDestinations {
        id
        name
    }
    unassociatedServiceLinesByInvoice(id: $id)
`
const InvoiceTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

const handleState = state => {
    const states = {
        'A_0' : 'PENDING',
        'A_1' : 'VALIDATED',
        'A_2' : 'ACCEPTED',
    }
    return states[state]
}

class InvoiceTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoiceTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InvoiceTableCreatorQueries, new InvoiceClickStrategy(), new InvoiceDisplayStrategy())
        tableCreator.setSelectedRowActionsStrategy(new InvoiceSelectedActions())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
        tableCreator.setInitialSortField('date', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'legalEntity',
                new DataMapper("account.legalEntity.name"),
                new f.FilterDescription(new f.TextFilter("legalEntity")),
                {label: 'Legal Entity'}
            ),
            new TableFieldDescription(
                'provider',
                new DataMapper("account.provider.name"),
                new f.FilterDescription(new f.TextFilter("provider")),
                {label: 'Vendor'}
            ),
            new TableFieldDescription(
                'country',
                new DataMapper("account.provider.country.name"),
                new f.FilterDescription(new f.TextFilter("country")),
                {label: 'Country'}
            ),
            new TableFieldDescription(
                'description',
                new DataMapper("account.description"),
                new f.FilterDescription(new f.TextFilter("description")),
                {label: 'Description'}
            ),
            new TableFieldDescription(
                'accountIdentifier',
                new DataMapper("account.identifier"),
                new f.FilterDescription(new f.TextFilter("accountIdentifier")),
                {label: 'Account ID'}
            ),
            new TableFieldDescription(
                'identifier',
                new DataMapper("identifier"),
                new f.FilterDescription(new f.TextFilter("identifier")),
                {label: 'Identifier'}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("state"),
                new f.FilterDescription(new f.InvoiceStateFilter("state")),
                {label: 'State', transform: new DataTransformer(({state})=> handleState(state))}
            ),
            new TableFieldDescription(
                'date',
                new DataMapper("date"),
                new f.FilterDescription(new f.PeriodFilter("date")),
                {label: 'Date', componentCreator: new DateComponentCreator(), excelDisplay: new DateDisplayCreator()}
            ),
            new TableFieldDescription(
                'totalCost',
                new DataMapper("totalCost"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Total Cost', componentCreator:new ThousandSeparatorCreator(2),excelTransform:new DataTransformer(({totalCost})=>Number(totalCost))}
            ),
            new TableFieldDescription(
                'currency',
                new DataMapper("billingRequest.driver.currency.code"),
                new f.FilterDescription(new f.TextFilter("currency")),
                {label: 'Currency'}
            ),
            new TableFieldDescription(
                'accountValidation',
                new DataMapper("account.validated"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Validation', onlyForm: true}
            ),
            new TableFieldDescription(
                'dueDate',
                new DataMapper("dueDate"),
                new f.FilterDescription(new f.PeriodFilter("dueDate")),
                {label: 'Due Date', onlyForm: true}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain', onlyForm: true}
            ),
            new TableFieldDescription(
                'canBeValidated',
                new DataMapper("canBeValidated"),
                new f.FilterDescription(new f.TextFilter("canBeValidated")),
                {label: 'Can Be Vallidated', onlyForm: true}
            )
        ]) 

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoiceTableCreator)

export {
    InvoiceTableCreator,
    InvoiceTableCreatorQueries
};
